import { FunctionComponent, PropsWithChildren } from 'react'
import { HasClassName } from 'models/HasClassName'
import classNames from 'classnames'

type Width = '2xl' | 'xl' | 'lg' | 'md'

interface Props extends HasClassName {
  width?: Width
  px?: 0 | 6
}

export const Container: FunctionComponent<PropsWithChildren<Props>> = ({ children, className, width = 'lg', px = 6 }) => {
  return (
    <div data-component="container" className={classNames('container mx-auto', className, {
      'max-w-screen-2xl': width === '2xl',
      'max-w-screen-xl': width === 'xl',
      'max-w-screen-lg': width === 'lg',
      'max-w-screen-md': width === 'md',
    }, {
      'px-0': px === 0,
      'px-6': px === 6,
    })}>
      {children}
    </div>
  )
}
