import { FunctionComponent, ReactNode } from 'react'

export interface Props {
  children: ReactNode
  network: string
  tag?: string | FunctionComponent
  url: string
}

interface TagProps {
  onClick: (e: Event) => void
  children: ReactNode
  href: string
}

export const ShareNetwork: FunctionComponent<Props> = ({ children, network, url, tag = 'a' }) => {
  const Tag = tag as FunctionComponent<TagProps>

  function generateLink() {
    switch (network.toLowerCase()) {
      case 'facebook':
        return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
      case 'twitter':
        return `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`
      case 'linkedin':
        return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`
    }

    throw new Error(`The key ${network.toLowerCase()} is not valid`)
  }


  const onClicked = () => {
    const left = (screen.width - 570) / 2
    const top = (screen.height - 570) / 2
    const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`

    window.open(generateLink(), 'NewWindow', params)
  }

  return (
    <Tag
      onClick={(e: Event) => {
        e.preventDefault
        onClicked()
      }}
      href="#"
      data-component="Tag"
    >
      {children}
    </Tag>
  )
}
