import { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import { useUtm } from 'hooks/utm'

export const BaseLayout: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  const { writeLocalStorage } = useUtm()

  useEffect(() => {
    writeLocalStorage()
  })

  return (
    <div>
      {children}
    </div>
  )
}
