import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { Blok, BlokProps } from '@storyblok/react'
import { FunctionComponent } from 'react'

interface Props extends Blok {
  assets: any[]
  heading: any
  text: any
  assetTitle: any
}

export const TextHeroSection: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  return (
    <SpacingWrapper
      background="IVORY"
      size="sm"
      data-component="TextHeroSection"
    >
      <Container
        className="text-center"
        width="xl"
      >
        <HeadingStoryblok
          blok={blok.heading[0]}
        />

        <MarkdownRenderer
          className="max-w-lg mx-auto mt-10 text-lg"
          markdown={blok.text}
        />

        <div className="h-px mt-20 mb-10 bg-black opacity-10" />

        <div className="mb-4 text-sm leading-none opacity-60">
          {blok.assetTitle}
        </div>

        <div className="grid w-56 grid-cols-2 gap-6 mx-auto md:grid-cols-4 md:w-auto md:max-w-lg md:items-center">
          {blok.assets.map((item, index) => (
            <div
              className="block opacity-60"
              // TODO: update key to id if exists.
              key={index}
            >

              <img
                src={item.filename}
                className="w-full"
                alt=""
              />
            </div>
          ))}
        </div>
      </Container>
    </SpacingWrapper>
  )
}
