import { Blok, BlokProps } from '@storyblok/react'
import { FunctionComponent } from 'react'
import Image from 'next/image'

export interface Props extends Blok {
  image: any
}

export const BlogImage: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  return (
    <div data-component="BlogImage" className="relative">
      <Image
        src={blok.image.filename}
        alt={blok.image.alt}
        className="w-full px-6"
        layout="fill"
      />

      <div className="max-w-screen-md px-6 mx-auto mt-4 text-sm text-indigo-500 text-opacity-60">
        {blok.image.name}
      </div>
    </div>
  )
}
