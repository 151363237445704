import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { QuoteDots } from '~/components/QuoteDots/QuoteDots'
import { Heading } from 'components/Scl/Typography/Heading'
import { HasClassName } from 'models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'
import { FunctionComponent } from 'react'
import Image from 'next/image'

export interface Props extends Blok {
  background: any,
  text: any,
  authorCompanyAsset: any,
  authorCompany: any,
  authorName: any,
  authorRole: any,
}

export const LargeQuoteSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <SpacingWrapper
      size="sm"
      background={blok.background}
    >
      <Container width="lg">
        <div className="flex flex-col items-center md:flex-row">
          <div className="w-full md:w-8/12">
            <QuoteDots className="mb-6" />

            <Heading className="mb-8">
              { blok.text }
            </Heading>

            <div className="flex items-center">
              <Image
                className="w-16 h-16 mr-4 rounded-full md:hidden"
                src="blok.authorCompanyAsset.filename"
                alt={blok.authorCompanyAsset.alt}
                width={72}
                height={72}
              />

              <div>
                <div className="mb-1 text-xs leading-none uppercase">
                  { blok.authorName }, { blok.authorRole }, { blok.authorCompany }
                </div>

                <Image
                  src="blok.authorCompanyAsset.filename"
                  alt={blok.authorCompanyAsset.alt}
                  className="h-6"
                  width={32}
                  height={32}
                />
              </div>
            </div>
          </div>

          <div className="hidden md:block md:w-4/12">
            <div
              style={{ backgroundImage: `url(${blok.authorCompanyAsset.filename})` }}
              className="w-64 h-64 ml-auto bg-center bg-no-repeat bg-cover rounded-full"
            />
          </div>
        </div>
      </Container>
    </SpacingWrapper>
  )
}
