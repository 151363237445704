import { InvoiceDiscountingCalculator } from '~/components/Storyblok/Partials/Hero/InvoiceDiscountingCalculator'
import { BusinessLoanCalculator } from '~/components/Storyblok/Partials/Hero/BusinessLoanCalculator'
import { SellInvoiceCalculator } from '~/components/Storyblok/Partials/Hero/SellInvoiceCalculator'
import { GlobalNavigation } from '~/components/Storyblok/Partials/Global/GlobalNavigation'
import { DownloadableMediaSection } from 'components/Storyblok/DownloadableMediaSection'
import { HeroPartnerFooter } from 'components/Storyblok/Partials/Hero/HeroPartnerFooter'
import { HeroGetStartedSection } from '~/components/Storyblok/HeroGetStartedSection'
import { BlogVerticalCta } from 'components/Storyblok/Partials/Blog/BlogVerticalCta'
import { SectionImageText } from 'components/Storyblok/Partials/SectionImageText'
import { HeroUspFooter } from 'components/Storyblok/Partials/Hero/HeroUspFooter'
import { GlobalFooter } from 'components/Storyblok/Partials/Global/GlobalFooter'
import { SectionLongText } from 'components/Storyblok/Partials/SectionLongText'
import { PressReleaseSection } from 'components/Storyblok/PressReleaseSection'
import { BlogPreamble } from 'components/Storyblok/Partials/Blog/BlogPreamble'
import { BlogArticleSection } from 'components/Storyblok/BlogArticleSection'
import { BlogTeaserSection } from 'components/Storyblok/BlogTeaserSection'
import { LargeQuoteSection } from 'components/Storyblok/LargeQuoteSection'
import { TrustpilotSection } from 'components/Storyblok/TrustpilotSection'
import { CollapseList } from 'components/Storyblok/Partials/CollapseList'
import { SectionIntro } from 'components/Storyblok/Partials/SectionIntro'
import { Config as GtmConfig, ENABLED as GtmEnabled, } from 'config/gtm'
import { OAuthHeroSection } from 'components/Storyblok/OAuthHeroSection'
import { ChangelogSection } from 'components/Storyblok/ChangelogSection'
import { BlogImage } from 'components/Storyblok/Partials/Blog/BlogImage'
import { BlogTitle } from 'components/Storyblok/Partials/Blog/BlogTitle'
import { BlogText } from 'components/Storyblok/Partials/Blog/BlogText'
import { CarouselSection } from 'components/Storyblok/CarouselSection'
import { TextHeroSection } from 'components/Storyblok/TextHeroSection'
import { PartnerPage } from '~/components/Storyblok/Pages/PartnerPage'
import { ProductSection } from 'components/Storyblok/ProductSection'
import { ContentSection } from 'components/Storyblok/ContentSection'
import { PartnerSection } from 'components/Storyblok/PartnerSection'
import { LinkUspSection } from 'components/Storyblok/LinkUspSection'
import { LeadForm } from '~/components/Storyblok/Partials/LeadForm'
import { QuoteSection } from '~/components/Storyblok/QuoteSection'
import { NumberSection } from 'components/Storyblok/NumberSection'
import { TextUsps } from 'components/Storyblok/Partials/TextUsps'
import { IconUsp } from 'components/Storyblok/Partials/IconUsp'
import { LinkSection } from 'components/Storyblok/LinkSection'
import { FormSection } from 'components/Storyblok/FormSection'
import { HeroSection } from 'components/Storyblok/HeroSection'
import { FaqSection } from 'components/Storyblok/FaqSection'
import { JobSection } from 'components/Storyblok/JobSection'
import { UspSection } from 'components/Storyblok/UspSection'
import { storyblokInit, apiPlugin } from '@storyblok/react'
import { Page } from 'components/Storyblok/Pages/Page'
import { StoryblokConfig } from 'config/storyblok'
import TagManager from 'react-gtm-module-custom-domain'
import type { AppProps } from 'next/app'
import { FunctionComponent, PropsWithChildren, ReactNode, useEffect } from 'react'
import Script from 'next/script'
import Head from 'next/head'
import { setLocale } from 'yup'
import { useValidation } from '~/hooks/validation'
import PiwikProProvider from '@piwikpro/next-piwik-pro'
import { PIWIK_CONTAINER_ID, PIWIK_CONTAINER_URL, ENABLED as PiwikEnabled } from 'config/piwik'

import 'components/Storyblok/Partials/SectionLongText.css'
import 'styles/global.css'

storyblokInit({
  accessToken: StoryblokConfig.accessToken,
  use: [apiPlugin],
  components: {
    InvoiceDiscountingCalculator,
    DownloadableMediaSection,
    BusinessLoanCalculator,
    HeroGetStartedSection,
    SellInvoiceCalculator,
    PressReleaseSection,
    BlogArticleSection,
    LargeQuoteSection,
    HeroPartnerFooter,
    BlogTeaserSection,
    TrustpilotSection,
    SectionImageText,
    ChangelogSection,
    OAuthHeroSection,
    GlobalNavigation,
    BlogVerticalCta,
    CarouselSection,
    TextHeroSection,
    SectionLongText,
    ProductSection,
    ContentSection,
    LinkUspSection,
    PartnerSection,
    HeroUspFooter,
    NumberSection,
    BlogPreamble,
    QuoteSection,
    CollapseList,
    SectionIntro,
    GlobalFooter,
    HeroSection,
    FormSection,
    LinkSection,
    PartnerPage,
    UspSection,
    JobSection,
    FaqSection,
    BlogImage,
    BlogTitle,
    BlogText,
    TextUsps,
    LeadForm,
    IconUsp,
    Page,
  },
})

const Tracking: FunctionComponent<{children: ReactNode}> = ({ children }) => {
  if (PiwikEnabled) {
    return (
      <PiwikProProvider
        containerUrl={PIWIK_CONTAINER_URL}
        containerId={PIWIK_CONTAINER_ID}
      >
        {children}
      </PiwikProProvider>
    )
  }
  else {
    return <>{children}</>
  }
}

export default function MyApp({ Component, pageProps }: AppProps) {
  const { config } = useValidation()

  setLocale(config)

  useEffect(() => {
    if (GtmEnabled) {
      TagManager.initialize(GtmConfig)
    }
  }, [])

  return (
    <Tracking>
      <>
        <Head>
          <meta name="facebook-domain-verification" content="lm4hrysoywg5rg3em8eisfrnt930zu" />
          <link rel="icon" type="image/png" href="/favicon/favicon_32x32.png" />
          <link rel="icon" type="image/png" href="/favicon/favicon_152x152.png" />
          <link rel="icon" type="image/png" href="/favicon/favicon_167x167.png" />
          <link rel="icon" type="image/png" href="/favicon/favicon_180x180.png" />
          <link rel="icon" type="image/png" href="/favicon/favicon_192x192.png" />
          <link rel="icon" type="image/png" href="/favicon/favicon_196x196.png" />
        </Head>

        <Script
          onLoad={() => (function(w: any){w.$kundo_chat = w.$kundo_chat || {};w.$kundo_chat.disable_widget=true;}(window))}
          src="https://static-chat.kundo.se/chat-js/org/1402/widget.js"
          strategy="lazyOnload"
          async
          defer
        />

        <Component {...pageProps} />
      </>
    </Tracking>
  )
}
